import React, { Component } from "react";
import "./contact.css";
import GoogleMapReact from 'google-map-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

class Contact extends Component {
  render() {
    return (
      <div className="ContactWrapper">
        <div className="ContactTitle">Nous Contacter c'est simple</div>
        <div className="ContactInfo">
          <div className="NumeroTel">
            <img className="BPhone" src="/Images/BPhone.png" alt="phone" />
            <a>01 43 14 38 08</a>
          </div>
          <div className="Mail">
            <img className="BMail" src="/Images/BMail.png" alt="mail" />
            <a>contact@wintech.fr</a>
          </div>
          <div className="Adresse">
            <img className="BHome" src="/Images/BHome.png" alt="home" />
            <a>33, Avenue Philippe Auguste 75011 Paris</a>
          </div>
        </div>
        <div className="GoogleMap">
          <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyALMl87Oi3xf8CwLlXNmbMYKxQyj0oasg8' }}
            defaultCenter={{ lat: 48.85156943063358, lng: 2.393325600852917 }}
            defaultZoom={15}
            draggable={false}
            keyboardShortcuts={false}
          >
            <Marker
              lat={48.85156943063358}
              lng={2.393325600852917}
            />
          </GoogleMapReact>
        </div>
      </div>
    );
  }
}

const Marker = () => (
  <div style={{ position: 'absolute', transform: 'translate(-50%, -50%)' }}>
    <FontAwesomeIcon icon={faMapMarkerAlt} className="marker" />
  </div>
);

export default Contact;
