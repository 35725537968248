import React, { Component } from "react";
import "./footer.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";

export default class Footer extends Component {
  render() {
    return (
      <div className="Body">
        <footer className="footer">
          <div className="footer_container">
            <div className="footer_row">
              <div className="footer_cologo">
                <img className="logofoot" src="/Images/Logo.png" alt="Logo" />

                <div className="logodesc">
                  Répondre à tous vos besoins en Télécom, Informatique et
                  Bureautique
                </div>
              </div>
              <div className="footer_col">
                <h4>A propos</h4>
                <ul>
                  <li>
                    <a href="#">+10 années d'experience</a>
                  </li>
                  <li>
                    <a href="#">Couverture natonale</a>
                  </li>
                  <li>
                    <a href="#">+300 Clients </a>
                  </li>
                </ul>
              </div>
              <div className="footer_col">
                <h4>Contact</h4>
                <ul>
                  <li>
                    <a href="/NousPresenter">Qui sommes nous ?</a>
                  </li>
                  <li>
                    <a href="/Contact">
                      33, Avenue Philippe Auguste 75011 Paris
                    </a>
                  </li>
                  <li>
                    <a href="/Contact">contact@wintech.fr</a>
                  </li>
                  <li>
                    <a href="/Contact">01 43 14 38 08</a>
                  </li>
                </ul>
              </div>

              <div className="footer_col">
                <h4>Nous Suivre</h4>
                <div className="social_links">
                  <a href="https://www.instagram.com/wintech75011/?hl=fr">
                    <i>
                      <FontAwesomeIcon icon={faInstagram}></FontAwesomeIcon>
                    </i>
                  </a>

                  <a href="https://fr.linkedin.com/company/wintech-telecom-informatique-bureautique">
                    <i>
                      <FontAwesomeIcon icon={faLinkedinIn}></FontAwesomeIcon>
                    </i>
                  </a>

                  <a href="#">
                    <i>
                      <FontAwesomeIcon icon={faFacebook}></FontAwesomeIcon>
                    </i>
                  </a>
                  <a href="#">
                    <i>
                      <FontAwesomeIcon icon={faTwitter}></FontAwesomeIcon>
                    </i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}
